<template>
  <v-dialog max-width="450px" v-model="show" persistent>
    <v-card>
      <v-toolbar flat class="text">
        <v-toolbar-title>
          Do you realy want to delete this {{selected > 0 ? selected : 1}} item(s)?
        </v-toolbar-title>
      </v-toolbar>
      <v-card-actions class="mr-7">
        <div class="buttons">
          <v-btn data-cy="cancel" text color="darkGrey" @click="show = false"
            >Cancel</v-btn
          >
          <v-btn
            type="submit"
            dark
            rounded
            depressed
            color="success"
            @click="triggerDeletion"
            data-cy="delete_button"
            >Delete</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({}),

  props: {
    visible: Boolean,
    selected: Number,
  },

  methods: {
    triggerDeletion() {
      this.$emit('remove')
    },
  },

  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
}
</script>

<style scoped>
.header {
  padding-top: 50 px;
}

.buttons {
  margin: auto;
}
</style>
